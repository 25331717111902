import type { MenuItem } from '~/interfaces/Header'

const defaultRegion = 'russia'

export const getHeaderTopMenu = (regionSlug: string): Array<MenuItem> => {
  const region = regionSlug || defaultRegion
  return [
    {
      title: 'Вакансии',
      ref: `/vacancies/region-${region}`
    },
    {
      title: 'Вузы',
      ref: '/universities'
    },
    {
      title: 'Колледжи',
      ref: '/colleges'
    },
    {
      title: 'Калькулятор ЕГЭ',
      ref: '/calculator-ege'
    },
    {
      title: 'Новости',
      ref: '/news'
    },
    {
      title: 'Клуб амбассадоров',
      ref: '/students/ambassadors'
    },
    {
      title: 'Агроклассы',
      ref: 'https://agroclasses.svoevagro.ru/?utm_source=svoevagro'
    },
    {
      title: 'Карьерные маршруты',
      ref: '/career-routes'
    },
    {
      title: 'Поддержка',
      ref: '/support'
    }
  ]
}

export const getHeaderMainLinks = (regionSlug: string): Array<MenuItem> => {
  const region = regionSlug || defaultRegion
  return [
    {
      title: 'В поисках работы',
      ref: `/looking-for-job/${region}`
    },
    {
      title: 'Ищу стажировку',
      ref: '/looking-for-internship'
    },
    {
      title: 'Для работодателей',
      ref: '/for-employers'
    }
  ]
}

export const getMobileSidebarMenu = (regionSlug: string): Array<MenuItem> => {
  const region = regionSlug || defaultRegion
  return [
    {
      title: 'Главная',
      ref: 'main'
    },
    {
      title: 'Вакансии',
      ref: `/vacancies/region-${region}`
    },
    {
      title: 'Вузы',
      ref: '/universities'
    },
    {
      title: 'Колледжи',
      ref: '/colleges'
    },
    {
      title: 'Калькулятор ЕГЭ',
      ref: '/calculator-ege'
    },
    {
      title: 'Новости',
      ref: '/news'
    },
    {
      title: 'Клуб амбассадоров',
      ref: '/students/ambassadors'
    },
    {
      title: 'Агроклассы',
      ref: 'https://agroclasses.svoevagro.ru/?utm_source=svoevagro'
    },
    {
      title: 'В поисках работы',
      ref: `/looking-for-job/${region}`
    },
    {
      title: 'Для работодателей',
      ref: '/for-employers'
    },
    {
      title: 'Карьерные маршруты',
      ref: '/career-routes'
    },
    {
      title: 'Поддержка',
      ref: '/support'
    }
  ]
}
