
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import ButtonYavagro from '~/components/UI/ButtonYavagro.vue'

@Component({
  components: { ButtonYavagro },
  middleware: ['geolocationUser']
})
export default class Geolocation extends Vue {
  readonly logo: string = '/icons/ui/geolocation.svg'

  @Prop({
    default: true
  })
  isDesktop!: boolean

  @Watch('$tstore.regionLocation.ShowModalRegionConfirm')
  isOpen(): void {
    if (this.$tstore.regionLocation.ShowModalRegionConfirm) {
      document.addEventListener('click', this.clickWithout)
    } else {
      document.removeEventListener('click', this.clickWithout)
    }
  }

  onClickGeolocation(): void {
    this.$tstore.regionLocation.setIsShowModalRegionConfirm(!this.$tstore.regionLocation.ShowModalRegionConfirm)

    this.$emit('on-click-geolocation')
  }

  clickWithout(e): void {
    const el = this.$refs.regionConfirm as HTMLElement
    const target = e.target
    if (target !== el && !el.contains(target)) {
      this.$tstore.regionLocation.setIsShowModalRegionConfirm(false)
    }
  }

  onSuccessAnswer(): void {
    this.$services.geolocation.setCookieRegion(this.regionItem)
    this.$tstore.regionLocation.setIsShowModalRegionConfirm(false)

    const region = this.$services.geolocation.getCookieRegion()

    if (this.$route.name === 'looking-for-job-slug' && region?.slug) {
      this.$router.push(`/looking-for-job/${region.slug}`)
    }

    if (this.$route.name?.includes('vacancies') && region?.slug) {
      this.$router.push(`/vacancies/region-${region.slug}`)
    }
  }

  onNegativeAnswer(): void {
    this.$tstore.regionLocation.setIsShowModalRegionConfirm(false)
    this.$tstore.regionLocation.setIsShowModalRegionChange(true)
  }

  get regionItem() {
    return this.$tstore.regionLocation.RegionLocationItem
  }

  mounted() {
    if (this.$tstore.regionLocation.ShowModalRegionConfirm && process.client) {
      document.addEventListener('click', this.clickWithout)
    }
  }
}
